:root {
  --bg-color: #8f8f8f; /* Начальный цвет фона */
}

.formula-img {
  padding: 1.25rem 0 1.25rem 0;
  width: 70%;
  width: 35vw;
  position: relative;
}

.column-3.replay-main {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: .1fr 1.5fr .35fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: stretch;
  align-items: center;
  justify-items: stretch;
  display: grid;
}
.exit{
  width: 8.7rem;
}
.replay-label{
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 1px;
  margin-left: 18%;
  padding: .13rem .75rem .25rem;
  font-size: .875rem;
  line-height: 1.5625rem;
}
#w-node-bc629063-85a6-b18f-2f16-d99b48b09db8-54442f70 {
  grid-area: 1 / 3 / 2 / 4;
  justify-self: end;
}

#w-node-_8f76eadb-8808-8946-5cbe-ff9822c8b5cb-54442f70 {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: end;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c099-54442f70 {
  grid-area: 1 / 2 / 2 / 3;
  justify-self: end;
}
#w-node-a6bdbf86-528d-8823-0996-187a6adcf71c-54442f70 {
  grid-area: 1 / 1 / 1 / 3;
  align-self: center;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70 {
  grid-area: 1 / 2 / 2 / 3;
  align-self: center;
}
#w-node-cc4be37e-14bb-d89a-c377-dc28b67587a7-54442f70 {
  grid-area: 1 / 3 / 2 / 4;
  justify-self: end;
}

.pause, .play {
  margin-bottom: -.3rem;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.pause:hover, .play:hover{
  opacity: 0.5;
}

.replay-line {
  width: 100%;
  height: .16rem;
  background-color: #000;
  display: block;
}

.replay-line-wrap {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: -1.5rem;
  display: flex;
}

.replay-line-vertical {
  width: .25rem;
  height: 1.5rem;
  background-color: #fff;
  display: flex;
  position: relative;
  top: -.75rem;
  left: 0%;
}

.speed-btn-wrap {
  grid-column-gap: .5rem;
  grid-row-gap: 0rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

a {
  text-decoration: underline;
}

.loader {
  width: 100%;
  height: 86.6%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  z-index: 33;
  top: auto;
  bottom: 2.25rem;
  backdrop-filter: blur(1px);
}

.logo{
  position: absolute;
  z-index: 6;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -100px;
  padding-left: 100px;
}

.padding-top{
  padding-top: 1.5rem;
}
.padding-top-mini{
  padding-top: 0.25rem;
}

.loader img {
  width: 10rem;

}

.full-width{
  width: 100%;
}


@keyframes rotateImage {

  0%,
  25% {
    transform: rotate(0deg);
  }

  26%,
  50% {
    transform: rotate(90deg);
  }

  51%,
  75% {
    transform: rotate(180deg);
  }

  76%,
  100% {
    transform: rotate(270deg);
  }
}

.rotating-image {
  animation: rotateImage 4s steps(4, end) infinite;
}




.side-sq-yin{
  width: 0.5625rem;
  height: 0.5625rem;
  display: inline-block;
  background-color: #000;
}

.side-sq-yang{
  width: 0.5625rem;
  height: 0.5625rem;
  display: inline-block;
  background-color: #ffffff;
}



.label-card-yang {
  color: #f8f7f4;
  border: 1px solid #fff;
  border-radius: 1px;
  margin-bottom: 0;
  margin-left: 1.5rem;
  padding: .13rem .75rem .25rem;
  font-size: .875rem;
  line-height: 1.5625rem;
}

.label-card-yin {
  color: #000000;
  border: 1px solid #000000;
  border-radius: 1px;
  margin-bottom: 0;
  margin-left: 1.5rem;
  padding: .13rem .75rem .25rem;
  font-size: .875rem;
  line-height: 1.5625rem;
}

.label-text {
  margin-bottom: .5em;
  font-size: .875rem;
  line-height: 1.6;
}

.label-text.white-text {
  font-size: .875rem;
}

.game-footer {
  width: 100%;
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
}

.error-bottom{
  position: absolute;
  width: 100%;
  background: #D00;
  display: flex;
  padding: 0.35rem 0rem 0.35rem 0rem;
  bottom: -10%;
  z-index: 99;
  justify-content: center
}
@keyframes slideTop {
  from { bottom: -10%; }
  to { bottom: 0; }
}

@keyframes slideBottom {
  from { bottom: 0; }
  to { bottom: -10%; }
}

.showFromBottom {
  animation: slideTop 0.5s ease forwards 0.5s;
}

.hideToBottom {
  animation: slideBottom 0.5s ease forwards;
}

.error-wrapp{
  display: flex;
  justify-content: space-between;
}

.error-close{
  background: none;
  color: white;
}

.error-message{
  color: #FFF;
  font-family: "Space Mono";
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem; /* 157.143% */
  letter-spacing: -0.02625rem;
  margin: 0;
}

.scroll-wrapper {
  overflow: auto;
  height: 70vh;
}

.yours {
  cursor: pointer;
  font-size: .857rem;
  line-height: .857rem;
}

.yours svg{
  height: 0.6rem;
  margin-left: 0.5rem;
}

.yours:hover {
  color: #7ef0ff;
}

.div-centered {
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.div-centered.pop {
  z-index: 3;
  height: 100%;
  background-color: rgba(143, 143, 143, .8);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.div-centered.pop.hide {
  display: none;
}

.demons-voted {
  color: #000;
  align-items: center;
  display: flex;
}

.demons-voted .vote{
  filter: invert(100%);
}

.paragraph.extra-small.margin-none{
  margin-bottom: 0;
}

.link-manifestation:focus + .change-white {
  color: white;
}

.link-manifestation.w-input.error{
  border-color: #9f290f;
}

.link-manifestation.w-input.error{
  border-color: #b52e10;
}

.yin-wrapper {
  width: 100%;
  grid-column: 1;
  grid-row: auto;
}

.yang-wrapper {
  width: 100%;
  color: #f8f7f4;
  grid-column: 2;
  grid-row: auto;
}
.yin-bg {
  background-color: #000;
  color: white;
  
}
.div-card-block-show-yours {
  width: 60%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: grid;
  row-gap: 0.5rem;
}

.div-card-block-show-yours.hide {
  display: none;
}

.yinyangbalance {
  width: 100%;
  flex-direction: column;
  margin-bottom: -1.7rem;
  display: flex;
}

.button {
  background-color: #000;
  display: inline-block;
  margin-bottom: 0;
  padding: .13rem .75rem .25rem;
  font-size: .875rem;
  line-height: 1.5625rem;
  color: white;
  text-decoration: none;
}

.button:hover {
  opacity: .92;
  color: #f8f7f4;
}

.button.blue {
  color: #1e1d1d;
  text-align: center;
  background-color: #7ef0ff;
  padding-top: .13rem;
}
.full-width-grid{
  grid-column: span 2;
}

.button.blue.full-width {
  justify-content: center;
  display: flex;
}

.button.blue.full-width.margin-top {
  margin-top: 4rem;
}

.margin-top{
  margin-top:1.25rem;
}

.button.blue.margin-right {
  margin-right: 2rem;
}

.button.margin-right{
  margin-right: 2rem;
}

.button.blue.hide {
  display: none;
}

.button.white {
  color: #1e1d1d;
  background-color: #fff;
  margin-left: 0;
  font-size: .875rem;
  line-height: 1.562rem;
}

.button.white.full-width {
  justify-content: center;
  margin-left: 0;
  display: flex;
}

.button.full-desc-margin-right {
  margin-right: -1rem;
}

.button.full-width {
  width: 100%;
  justify-content: center;
  display: flex;
}

.button.orange {
  background-color: #f67052;
}

.button.orange.remap {
  position: absolute;
  left: auto;
  right: 20vw;
}

.remap-yang{
  position: absolute;
  left: 73%;
  margin: 0;

}

.remap-yin{
  position: absolute;
  right: 73%;
  margin: 0;
}

.remap-label{
  color: #000000;
    border: 1px solid #000000;
    border-radius: 1px;
    margin-bottom: 0;
    margin-top: 2rem;
    padding: 0.13rem 0.75rem 0.25rem;
    text-transform: uppercase;
}

.button.hide {
  display: none;
}

.button.nomargin{
  margin: 0;
}

.angels-voted {
  color: #f8f7f4;
  align-items: center;
  display: flex;
}

.stretch {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.column-3 {
  width: 100%;
  margin-top: 1rem;
  display: flex;
}

.column-3.between {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  flex-direction: row;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  justify-content: stretch;
  align-items: center;
  justify-items: stretch;
  display: grid;
}

.column-3.between.hide {
  display: none;
}

.div-card-block-manifested {
  width: 100%;
  height: 100%;
  flex-flow: column wrap;
  align-content: stretch;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.div-card-block-manifested.hide {
  display: none;
}

.div-card-block-potential {
  width: 25rem;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.div-card-block-potential.hide {
  display: none;
}

.choose {
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-top: 3rem;
  display: flex;
}

.yin-line {
  width: 50%;
  height: .5625rem;
  background-color: #000;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.link {
  font-weight: 300;
  text-decoration: underline;
}

.link-popup-wrapper {
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.balance-index {
  font-size: 1.5rem;
  line-height: 1.375rem;
}

.polygon {
  position: relative;
  bottom: .8rem;
  width: 10%;
  z-index: 3;
  /* left: 49%; */
}

.polygon-remap {
  position: relative;
  bottom: 1.4rem;
  width: 10%;
  z-index: 3;
  /* left: 49%; */
}

.canvas-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  /* border-style: solid;
  border-color: #4eff3f; */
  display: flex;
  align-items: flex-end;
  left: 0;
  top: 0;
}

.canvas-wrapper-internal {
  width: 100%;
  position: relative;
  /* height: 80vh; */
  /* bottom: 2rem; */
  height: 100vh;
  text-align: center;
}

.canvas-wrapper-internal canvas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}

.yang {
  width: 7rem;
  height: 9rem;
  background-image: url('../images/yang-static.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.yang:hover {
  background-image: url('../images/yang-hover.svg');
}

.yang:active {
  background-image: url('../images/yang-selected.svg');
}

.yang.active {
  background-image: url('../images/yang-selected.svg');
}

.vline {
  width: .0625rem;
  height: 2.5rem;
  background-color: #000;
  position: relative;
}

.vline.white {
  background-color: #fff;
}

.vline.attach {
  left: 1px;
}

.link-manifestation {
  width: 100%;
  height: 5rem;
  max-height: 5rem;
  min-width: 20rem;
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid #000;
  margin-bottom: -8px;
  padding-top: 8px;
  font-size: .85rem;
  overflow: auto;
}

.link-manifestation:hover {
  opacity: .45;
  border-color: #fff;
}

.link-manifestation:active {
  opacity: 1;
}

.link-manifestation:focus {
  opacity: 1;
  border-color: #fff;
}

.link-manifestation::-ms-input-placeholder {
  color: rgba(30, 29, 29, .41);
}

.link-manifestation::placeholder {
  color: rgba(30, 29, 29, .41);
}

.paragraph {
  margin-bottom: 0;
  font-size: 1.125rem;
  line-height: 1.6;
}

.paragraph.extra-small {
  white-space: normal;
  font-size: .875rem;
}

.paragraph.extra-small.margin-bottom {
  margin-bottom: .5rem;
}

.paragraph.extra-small.margin-bottom.hide {
  display: none;
}

.paragraph.extra-small.error {
  color: #9f290f;
}

.paragraph.extra-small.link-text {
  width: 100%;
  white-space: normal;
  word-break: break-all;
  overflow-wrap: normal;
  object-fit: contain;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: underline;
  display: inline;
  color: #000;
}

.paragraph.extra-small.hide {
  display: none;
}

.paragraph.small {
  font-size: 14px;
}

.paragraph.meta {
  font-size: .6875rem;
}
.paragraph.meta-description {
  font-size: .6875rem;
}
.alert-text{
  color: #FFB802;
  margin-bottom: 0.5rem;
  line-height: 1.375rem;
}
.top-panel-wrapper{
  display: flex;
}

.footer-desc {
  z-index: 999;
  width: 100%;
  background-color: #8f8f8f;
  padding-top: 2.31rem;
  padding-bottom: 2.25rem;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.heading {
  letter-spacing: -.03em;
}

.yours-link {
  color: #7ef0ff;
  word-break: break-all;
  padding-bottom: 0.5rem;
}

.your-link-text{
  color: #00A3FF;
font-family: "Space Mono";
font-size: 0.75rem;
font-style: normal;
font-weight: 400;
line-height: 1rem;
letter-spacing: -0.0225rem;
text-decoration-line: underline;
word-break: break-all;
}

.together-div {
  display: flex;
}

.together-div.align-center {
  align-items: center;
}

.yours-grid {
  z-index: 1;
  width: 100%;
  grid-column-gap: 2.25rem;
  grid-row-gap: .5rem;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column dense;
  align-content: start;
  justify-content: start;
  align-items: stretch;
  justify-items: stretch;
  padding-bottom: 0.5rem;
  display: grid;
  position: relative;
  overflow: auto;
}

.balancetext-wrapper {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
}



.ill-text-line {
  color: #000;
  align-items: center;
  display: flex;
}

.ill-text-line.margin-bottom {
  margin-right: 2.5rem;
}

.container {
  width: 100%;
  height: auto;
  max-width: 100%;
  flex-direction: column;
  flex: none;
  justify-content: flex-start;
  align-items: center;
  /* padding-left: 11.5rem;
  padding-right: 11.5rem; */
  display: flex;
  position: static;
  top: 0%;
  left: 0%;
  right: 0%;
}



.block-label-heading {
  font-size: 3rem;
  line-height: 3rem;
}

.block-label {
  font-size: 1.5rem;
    line-height: 1.5rem;
  font-size: 1.5rem;
}

.header-desc {
  justify-content: flex-end;
  padding-top: 1.25rem;
  padding-bottom: 4.75rem;
  display: flex;
  position: relative;
  transform: translateX(1%);
}

.yang-line {
  width: 50%;
  height: .5625rem;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.hr {
  width: 40%;
  height: 1px;
  background-color: #000;
  margin-left: auto;
  margin-right: 0;
}

.hr.horizontal {
  z-index: 0;
  width: 100%;
  position: relative;
  top: -4px;
  bottom: auto;
}

.hr.vertical {
  width: 1px;
  height: 70vh;
  margin-right: -2px;
  position: absolute;
  right: auto;
}

.description-wrapper {
  width: 100%;
  position: relative;
  z-index: 2;
  height: auto;
  min-height: 8rem;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  display: flex;
}



.description-wrapper.mob-show {
  display: none;
}

.yours-wrapper {
  width: 100%;
}

.yours-wrapper.hide {
  display: none;
}

.place-link-wrapper {
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  display: flex;
  
}

.vote {
  width: 1.125rem;
  height: 1.125rem;
  background-color: #93ffb1;
  margin-right: .5rem;
}

.vote.one {
  background-color: #fff;
}

.vote.twice {
  background-color: rgba(255, 255, 255, .85);
  background-image: radial-gradient(circle, #000, #000 50%, #fff 50%);
}



.yin {
  width: 7rem;
  height: 9rem;
  background-image: url('../images/yin-static.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

.yin:hover {
  background-image: url('../images/yin-hover.svg');
}

.yin:active {
  background-image: url('../images/yin-selected.svg');
}

.yin.active {
  background-image: url('../images/yin-selected.svg');
}

.popup-wrapper {
  width: 34.5rem;
  height: 34.5rem;
  flex-flow: wrap;
  align-content: space-between;
  justify-content: flex-start;
  align-items: stretch;
  padding: 2.25rem 2.5rem;
  display: flex;
}

.popup-wrapper.yang-bg {
  background-color: #fff;
  align-content: space-between;
}

.pop-error{
  background: #D00;
  color: white;
  position: absolute;
  width: 34.5rem;
  left: 50%;
  transform: translateX(-50%);
  top: 4.5rem;
  padding: 0.3125rem 2.25rem 0.375rem 2.25rem;
}
.pop-error p{
  margin: 0px;
}

.div-2-3 {
  width: 70%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 0;
  display: flex;
}

.div-5-6 {
  width: 90%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-right: 0;
  display: flex;
}

.status-ellipse {
  width: .75rem;
  height: .75rem;
  background-color: #4eff3f;
  border-radius: 100%;
  margin-right: .5rem;
}

.status-ellipse.orange {
  background-color: #F67052;
}

.div-1-3 {
  width: 50%;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.div-1-3.align-right {
  width: 30%;
  min-height: 0%;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-end;
  z-index: 3;
}

.div-1-6 {
  width: 10%;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  display: flex;
  overflow: hidden;
}

.div-1-6.align-right {
  width: 10%;
  min-height: 0%;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-end;
}

.div-6-6 {
  width: 100%;
    position: relative;
    z-index: 2;
    height: auto;
    min-height: 8rem;
    flex-flow: wrap;
    justify-content: space-between;
    align-items: stretch;
    display: flex;
}
.div-6-6.hide{
  display: none;
}

.vlinemini {
  width: .0625rem;
  height: 1rem;
  background-color: #000;
  position: relative;
  bottom: .4rem;
  right: 50%;
}

.vlinemini.white {
  height: 1rem;
  background-color: #fff;
  position: relative;
  bottom: .4rem;
  right: 50%;
}

.full-desc-wrapper {
  place-items: center;
  display: grid;
  align-content: space-between;
}

.full-desc-wrapper {
  z-index: 3;
  height: 100vh;
  background-color: #8f8f8f;
  width: 100%;
}

.pop  {
  position: absolute;
  top:-110%;
  
}

.mini-description{
  padding-top: 1.25rem;
}

.popup-wrapper.choose-side{
  background: #8f8f8f;
  align-content: space-between;
  
}

.top-align{
  display: flex;
  flex-wrap: wrap;
}

.top-panel{
  /* background: #8f8f8f; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: absolute;
  top:-130%;
  padding-top: 1.25rem;
}



@keyframes slideIn {
  from { top: -135%; }
  to { top: 0; }
}

@keyframes slideOut {
  from { top: 0; }
  to { top: -135%; }
}

.visible {
  animation: slideIn 0.3s ease forwards 0.3s;
}

.hidden {
  animation: slideOut 0.3s ease forwards;
}

.popup-content {
  transition: opacity 0.5s ease-in-out 0.5s; /* Плавное изменение прозрачности с задержкой */
}
.fade-in {
  transition: opacity 0.5s linear 0.3s;
  opacity: 1; /* Полная видимость, когда попап активен */

}

.fade-out {
  transition: opacity 0s linear 0s;
  opacity: 0; /* Полная видимость, когда попап активен */
}

.close{
  position: absolute;
  right: 0;
  z-index: 7;
}

.ddc-game {
  width: 100%;
  height: 100vh;
  background-color: var(--bg-color);
  transition: background-color 1s; 
  flex-direction: row;
  padding-top: 0;
  font-family: Space Mono, sans-serif;
  display: flex;
  position: relative;
  overflow: hidden;
}




.white-text {
  color: #f8f7f4;
}
.white-text.hide{
  display: none;
}
.column-2 {
  width: 100%;
  grid-column-gap: 16px;
  grid-row-gap: 0.5rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  justify-content: flex-start;
  align-items: start;
  justify-items: start;
  margin-right: 0;
  display: grid;
}

.column-2.stretch{
  justify-items: stretch;
}

.column-2.stretch-grid {
  grid-template-columns: 0.5fr 1fr;
}

.column-2._1-bigger {
  grid-row-gap: 8.25rem;
  grid-template-columns: 1.5fr 1fr;
  align-content: start;
  justify-content: start;
  align-items: start;
  justify-items: start;
}

.column-2.hide {
  display: none;
}

.yinyang-wrapper {
  width: 100%;
  display: flex;
}

@media screen and (min-width: 1440px) {
  #w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a43-54442f70 {
    justify-self: end;
  }
  .macfix {
    display: none;
  }
  /* .canvas-wrapper-internal{
    width: 100vw;
    position: relative;
  } */
  .label-card,
  .label-text {
    letter-spacing: 0;
  }



  .button.white {
    margin-left: 1rem;
  }

  .column-3 {
    flex-direction: row;
    display: flex;
  }

 

 

  .paragraph {
    letter-spacing: 0;
  }

  .paragraph.extra-small.error {
    color: #9f290f;
  }

  .container {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex;
    overflow: hidden;
  }

  .hr {
    width: 50%;
    margin-left: auto;
    margin-right: 0;
  }

  .column-2 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    flex-direction: row;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
  }

  .column-2.hide {
    display: none;
  }
}

@media screen and (min-width: 1920px) {
  .hr {
    width: 60%;
  }
  

  .container {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  
  
}
@media screen and (min-width: 1550px) {
  .macfix {
    display: block!important;
  }
  .macfix.hide{
    display: none!important;
  }
}

@media only screen and (min-width: 1336px) {
  .macfix {
    display: none;
  }
  .meta-description{
    display: none;
  }
}

@media only screen and (min-width: 2560px) {
  .popup-wrapper{
    width:39rem;
  }

  .paragraph.extra-small {
    font-size: 1rem;
  }

  .button {
    font-size: 1rem;
    padding: 0.33rem 0.95rem 0.45rem;
  }

  .button.white {
    font-size: 1rem;
    padding: 0.33rem 0.95rem 0.45rem;
  }

  .button.blue {
    font-size: 1rem;
    padding: 0.33rem 0.95rem 0.45rem;
  }

  .label-text {
    font-size: 1rem;
    padding: 0.33rem 0.95rem 0.45rem;
  }

  .polygon {
    left: 49.5%;
  }
  .yinyangbalance{
    margin-bottom: -3rem;
  }
}

@media screen and (max-width: 1024px) {
  .game-footer {
    display: none;
  }

  @keyframes slideIn {
    from { top: -130%; }
    to { top: 0; }
  }
  
  @keyframes slideOut {
    from { top: -130%; }
    to { top: -130%; }
  }
  .div-centered {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .column-3 {
    flex-direction: row;
  }

  .choose,
  .link-popup-wrapper {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
  }

  .canvas-wrapper-internal {
    display: none;
  }
  .logo{
    display: none;
  }

  .yang {
    width: 6rem;
    height: 7rem;
    background-image: url('../images/yang-static.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .yang:hover {
    background-image: url('../images/yang-hover.svg');
    background-position: 50%;
    background-attachment: scroll;
  }

  .yang:active {
    background-image: url('../images/yang-active.svg');
  }

  .paragraph.extra-small {
    margin-bottom: 0;
  }

  .paragraph.small {
    margin-bottom: 0;
    font-size: 1.375rem;
  }

  .paragraph.small.white-text {
    font-size: .875rem;
  }

  .paragraph.meta {
    margin-bottom: 0;
  }

  .heading.white-text {
    font-size: 1.25rem;
  }

  .together-div {
    width: 100%;
    grid-column-gap: 1.5rem;
    grid-row-gap: 1.5rem;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .together-div.vertical {
    width: 18.375rem;
    height: 60vh;
    text-align: center;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .container {
    height: 100vh;
    justify-content: space-between;
    padding: 0 2em;
    font-size: 14px;
    display: flex;
    overflow: hidden;
  }

  .img-medium {
    width: 10rem;
  }

  .hr {
    width: 30%;
    margin-left: auto;
  }

  .description-wrapper {
    width: 100%;
    margin-top: 1em;
  }

  .description-wrapper.mob-show {
    height: 100%;
    min-height: 0;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 0;
    display: flex;
    padding-top: 2.25rem;
  }

  .description-wrapper.mob-hide {
    display: none;
  }

  .yin {
    width: 6rem;
    height: 7rem;
    background-image: url('../images/yin-static.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: scroll;
  }

  .yin:hover {
    background-image: url('../images/yin-hover.svg');
  }

  .yin:active {
    background-image: url('../images/yin-selected.svg');
  }

  .status-ellipse {
    margin-right: 5px;
  }

  .full-desc-wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .column-2 {
    flex-direction: row;
  }
}

@media screen and (max-width: 767px) {

  .label-card,
  .label-text,
  .paragraph {
    color: #1e1d1d;
  }

  .paragraph.small.white-text {
    color: #fff;
  }

  .together-div {
    grid-column-gap: .625rem;
    grid-row-gap: .625rem;
    flex-direction: column;
    align-items: center;
  }

  .container {
    height: auto;
    padding-bottom: 40px;
  }

  .hr {
    width: 1px;
    height: 100px;
    margin-left: 25%;
  }

  .div-2-3,
  .div-1-3 {
    width: 100%;
  }
}

@media screen and (max-width: 479px) {
  .paragraph {
    font-size: 1.375rem;
  }

  .paragraph.extra-small {
    font-size: .75rem;
  }

  .paragraph.small {
    font-size: .875rem;
    line-height: 1.375rem;
  }

  .paragraph.small.white-text {
    color: #f8f7f4;
  }

  .paragraph.meta {
    margin-bottom: 0;
    line-height: .875rem;
  }

  .paragraph.meta-description {
    font-size: .875rem;
  }

  .paragraph.meta.white-text {
    color: #f8f7f4;
  }

  .heading {
    font-size: 1.5rem;
  }

  .ill-text-line {
    justify-content: center;
    align-items: center;
  }

  .ill-text-line.margin-bottom {
    margin-bottom: .625rem;
  }

  .container {
    padding-bottom: 0;
    padding-left: 32px;
    padding-right: 32px;
  }

  .hr {
    width: 1px;
    height: 100px;
    margin-left: 31%;
  }

  .description-wrapper.mob-show {
    text-align: center;
    justify-content: space-between;
  }

  .div-2-3 {
    width: 100%;
    margin-bottom: 1em;
  }

  .status-ellipse {
    width: .75rem;
    height: .75rem;
    background-color: #4eff3f;
    border-radius: 100%;
    flex: none;
    margin-right: .3125rem;
    padding-bottom: 0;
    padding-right: 0;
  }

  .div-1-3 {
    width: 100%;
    margin-bottom: 1em;
  }
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8976-54442f70 {
  justify-self: end;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a897a-54442f70 {
  grid-area: 1 / 1 / 2 / 2;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a89a9-54442f70 {
  grid-area: 2 / 1 / 3 / 2;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a89bc-54442f70 {
  grid-area: 3 / 1 / 4 / 2;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a89e8-54442f70 {
  grid-area: 4 / 1 / 5 / 2;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a89ff-54442f70 {
  grid-area: 1 / 2 / 5 / 3;
  align-self: start;
  justify-self: end;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a3e-54442f70 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: end;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a43-54442f70 {
  justify-self: end;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8a8e-54442f70 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bef-54442f70 {
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf1-54442f70 {
  grid-area: 1 / 2 / 2 / 3;
  align-self: start;
  justify-self: auto;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8bf2-54442f70 {
  grid-area: 1 / 2 / 2 / 3;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c04-54442f70 {
  grid-area: 1 / 2 / 2 / 3;
  justify-self: center;
}

#w-node-_3564e758-06ff-45c0-7369-6ef5d43a8c06-54442f70 {
  grid-area: 1 / 3 / 2 / 4;
  justify-self: end;
}


